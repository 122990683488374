import React, { useEffect, useState } from 'react'
import logo from './fwmonkey.svg';
import { API } from 'aws-amplify';
import * as queries from './graphql/queries';

// import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

function App() {
  const [echoReply, setEchoReply] = useState("Name?")
  const [brand, setBrand] = useState("unknown")
  const [board, setBoard] = useState("unknown")

  useEffect(() => {
    fetchEcho()
  }, [])

  async function fetchEcho() {
    try {
        const response = await API.graphql({ query: queries.marlinConfig, variables: {brand: brand, board: board}});
        console.log(response)
        setEchoReply(response.data.MarlinConfig)
    } catch (err) { console.log('error fetching echo: ' + err) }
  }


  return (
    <div className="bg-white">
      <header>
        <div className="flex bg-white items-center justify-between">
          <div className="flex items-center">
            <img className="h-14" src={logo} alt="logo" />
            <h1 className="text-2xl font-bold text-indigo-700">Firmware Monkey</h1>
          </div>
          <div>
            <AmplifySignOut />
          </div>
        </div>
        <div className="bg-yellow-500 h-2"></div>
        <div className="bg-black h-1"></div>
      </header>
      <nav>
      </nav>

      <main>
        <div className="grid grid-cols-2 m-3">
          <div className="m-3">
            <form>
              <div><label for="printerbrand">Which Printer Brand?</label>
                <select name="printerbrand" className="rounded">
                  <option value="creality">creality</option>
                  <option value="voron">voron</option>
                  <option value="blv">blv</option>
                </select>
              </div>
              <div>
                <label for="boardname">Which Board?</label>
                <select name="boardname" className="rounded">
                  <option value="skr14">skr 1.4</option>
                  <option value="skr13">skr 1.3</option>
                  <option value="skre3">skr e3</option>
                </select>
              </div>
            </form>
          </div>
          <div className="m-3 text-sm"><code className="block whitespace-pre overflow-x-scroll">{echoReply}</code></div>
        </div>
      </main>
    </div>
  );
}

export default withAuthenticator(App);